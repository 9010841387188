import * as React from "react"
import { Link } from "gatsby"

const TableContents = ({ headings }) => (
  <div style={{ color: '#5A5A5A', fontSize: '14px', position: 'fixed' }}>
    <div style={{ fontWeight: 'bold', marginBottom: 12 }}>TABLE OF CONTENTS</div>
    <div>
      {headings.map((heading) => {
        if (heading.depth > 4 || !heading.value) {
          return <div key={Math.random(1000)} />;
        }
        return (
          <div key={heading.value} style={{ marginBottom: 12 }}>
            <Link
              style={{ color: '#5A5A5A' }}
              to={`#${heading.value.replace(/\s+/g, "-").toLowerCase()}`}
            >
              {heading.value}
            </Link>
          </div>
        );
      })}
    </div>
  </div>
);

export default TableContents