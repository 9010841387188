import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Menu } from "antd";
import { css, } from '@emotion/react'
import "./layout.css"
import Sidebar from "./Sidebar"
import { StaticImage } from "gatsby-plugin-image"
// import Pagination from "./Pagination"
// import logoImg from "../images/logo.svg"


const LayoutWrapper = ({ children, location, tableOfContent }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: {fields: frontmatter___order}) {
        nodes {
          id
          frontmatter {
            title
            order
          }
          fields {
            slug
          }
        }
      }
    }
  `)
  const headerHeight = "52px"
  const bodyWidth = "1248px"
  return (
    <div
      css={css`
      background-color: #fafafa;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `}
    >
      <div id="headerWrap" css={css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: ${headerHeight};
      background: linear-gradient(91.86deg, #0065ff 0%, #a100ff 100%);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
      `}>
        <div css={css`
        width: 100%;
        max-width: ${bodyWidth};
        margin: 0 24px;
      `}>
          <div css={css`
        display: flex;
        align-items: center;
      `}>
            <StaticImage
              src={'../images/logo.png'}
              alt="aixp-logo"
              style={{ marginBottom: 0, marginRight: 128, width: 48 }}
            />
            <Menu
              mode="horizontal"
              css={css`
              background: transparent;
              border: none;
              .ant-menu-item {
                height: 42px;
              }
              .ant-menu-item {
                color: rgba(255, 255, 255, 0.7);
                font-size: 14px;
              }
              .ant-menu-item: hover {
                color: white;
                border-bottom: 3px solid #d999ff;
              }
              .ant-menu-item-selected {
                background: transparent !important;
                color: white !important;
                border-bottom: 3px solid #d999ff;
              }
              li:: after{
                border-bottom: none !important;
              }
              ant-menu-item-active {
                color: red;
                border-bottom: 3px solid #d999ff;
              }
            `}
            >
              <Menu.Item key="/">
                Guides
                <Link to="/">
                </Link>
              </Menu.Item>
              <Menu.Item key="/pintegration">
                Developers
                <Link to="/integration">
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </div>
      <div
        id="bodyWrapper"
        css={css`
        padding-top: ${headerHeight};
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow-y: scroll;
        `}>
        <div
          id="body"
          css={css`
          width: 100%;
          max-width: ${bodyWidth};
          display: grid;
          grid-template-columns: 292px 1fr 292px;
          `}
        >
          <div style={{ maxWidth: 292, borderRight: '1px solid #ebebeb' }}>
            <Sidebar data={data} location={location} />
          </div>
          <div style={{
            maxWidth: 664,
            padding: 40
          }}>
            {children}
            {/* <Pagination data={data} location={location} /> */}
          </div>
          <div style={{ padding: '108px 20px 0' }}>
            {!!tableOfContent ? tableOfContent : ""}
          </div>
        </div>
      </div>
    </div>
  )
}

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWrapper
