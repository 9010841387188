import React from "react"
import { graphql } from "gatsby"
import LayoutWrapper from '../components/LayoutWrapper'
import TableContents from '../components/TableContents'
import { css } from '@emotion/react'

export default function Docs(props) {
  const data = props.data.markdownRemark
  return (
    <div>
      <LayoutWrapper location={props.location} tableOfContent={<TableContents headings={props.data.markdownRemark.headings} />}>
        <h1 level={1}
          style={{ fontFamily: 'Poppins, georgia, sans-serif' }}
        >
          {data.frontmatter.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: data.html }}
          css={css`
          p, li{
            font-family: 'Inter', sans-serif;
          }
          word-break: break-word;

          th {
            font-size: 14px;
          }

          td {
            font-size: 12px
          }

          table{
            table-layout: fixed;
            width: 100%;
          }

          `}
        ></div>
      </LayoutWrapper>
    </div>
  )
}

export const getPostData = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
      headings{
        value
        depth
      }
      html
    }
  }
`