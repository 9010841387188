import * as React from "react"
import { Link, navigate } from "gatsby"
import {
  Input,
  Menu,
} from "antd";
import { css } from '@emotion/react'

const Sidebar = ({ data, location }) => {
  data.allMarkdownRemark.nodes.sort((a, b) =>
    a.fields.slug.split("/").length > b.fields.slug.split("/").length
  )
  const hasChildMap = {}
  const allNodes = data.allMarkdownRemark.nodes.map(node => {
    const slug = node.fields.slug.split("/")
    const nodeObject = { ...node }
    if (slug.length > 2) {
      const parentSlug = slug.slice(0, slug.length - 1).join("/")
      nodeObject["parentSlug"] = parentSlug
      hasChildMap[parentSlug] = hasChildMap[parentSlug] ? [...hasChildMap[parentSlug], nodeObject].sort((a, b) => a.frontmatter.order > b.frontmatter.order) : [nodeObject]
    }
    return nodeObject
  })
  const parentDepthMap = {}
  Object.keys(hasChildMap).forEach(key => {
    const findNode = allNodes.find(node => node.fields.slug === key)
    const nodeObject = {
      node: findNode,
      key: key,
      childNodes: hasChildMap[key].map(child => {
        return {
          node: child,
          key: child.fields.slug
        }
      })
    }
    parentDepthMap[key.split("/").slice(1).length] = parentDepthMap[key.split("/").slice(1).length] ?
      [...parentDepthMap[key.split("/").slice(1).length], nodeObject].sort((a, b) => a.key === '/allAboutAixp' ?
        false : a.node?.frontmatter.order > b.node?.frontmatter.order) : [nodeObject]
  })
  if (parentDepthMap[1]) {
    const firstParentNodes = allNodes.filter(node => !node.parentSlug).filter(node => !hasChildMap[node.fields.slug] && node.fields.slug)
    const allAboutAixpNode = allNodes.find(node => !node.fields.slug)
    const indexNodeIndexInDepthMap = parentDepthMap[1].findIndex(node => node.key === "/allAboutAixp")
    parentDepthMap[1][indexNodeIndexInDepthMap]["node"] = allAboutAixpNode
    firstParentNodes.forEach(node => {
      parentDepthMap[1].push({
        node,
        key: node.fields.slug,
      })
    })
    parentDepthMap[1].sort((a, b) => a.node?.frontmatter.order > b.node?.frontmatter.order)
  }
  for (let i = Object.keys(parentDepthMap).length - 1; i > 0; i--) {
    parentDepthMap[i] = parentDepthMap[i].map(prt => {
      if (!prt.childNodes) return prt
      const newChildren = prt.childNodes.map(chld => {
        const findChild = parentDepthMap[i + 1].find(chld2 => chld.key === chld2.key)
        if (findChild) {
          return {
            ...findChild
          }
        }
        return {
          ...chld
        }
      })
      const childWithkeySlug = parentDepthMap[i + 1].filter(chld2 => {
        const slug = chld2.key.split("/")
        slug.pop()
        return slug.join("/") === prt.key && !chld2.node
      })
      if (childWithkeySlug.length) {
        newChildren.push(...childWithkeySlug)
      }
      return {
        ...prt,
        childNodes: newChildren
      }
    })
  }



  ///////old code

  const parentNodes = []
  const childNodes = []
  data.allMarkdownRemark.nodes.forEach(node => {
    const slug = node.fields.slug.split("/").slice(1)
    if (slug.length < 2) {
      parentNodes.push({
        ...node,
        childrenNodes: []
      })
    } else {
      childNodes.push(node)
    }
  })
  childNodes.forEach(node => {
    if (node.fields.slug.includes("allAboutAixp")) {
      parentNodes[0].childrenNodes.push(node)
    } else {
      const index = parentNodes.findIndex(parentNode => !!parentNode.fields.slug && node.fields.slug.includes(parentNode.fields.slug))
      if (!!index) parentNodes[index].childrenNodes.push(node)
    }
  })
  const subPath = location.pathname.includes("allAboutAixp") ? "/" : location.pathname.split("/").slice(0, 2).join("/")
  // const [selectedKey, setSelectedKeys] = React.useState([location.pathname])
  // const [openKeys, setOpenKeys] = React.useState([subPath]);

  // const onOpenChange = keys => {
  //   if (keys.length > 1) {
  //     setOpenKeys([keys[keys.length - 1]]);
  //   } else {
  //     setOpenKeys(keys);
  //   }
  // };

  /////////////////////
  const locationAIIXP = "" + location.pathname
  const locationArray = location.pathname.split("/").slice(2)
  const locationKeys = locationArray.map((str, idx) => ["", ...locationArray.slice(0, idx + 1)].join("/")).map(str => str === "/" ? '/allAboutAixp' : str)

  const defaultOpenKeys = locationKeys
  const defaultSelectedKeys = [...locationKeys].pop()

  const [selectedKey, setSelectedKeys] = React.useState([defaultSelectedKeys])
  const [openKeys, setOpenKeys] = React.useState(defaultOpenKeys);

  const onOpenChange = keys => {
    setOpenKeys(keys)
  };

  React.useEffect(() => {
    setSelectedKeys([defaultSelectedKeys])
    setOpenKeys(defaultOpenKeys)
  }, [defaultSelectedKeys])


  // function menuItems(array) {
  //   return array.map(node => {
  //     if (node.childNodes && node.childNodes.length) {
  //       const title = node.node ? node.node.frontmatter.title : node.key.split("/").pop()
  //       return <Menu.SubMenu key={node.key} title={<div
  //         onClick={() => {
  //           if (node.node) {
  //             navigate(node.key === "/allAboutAixp" ? "/" : node.key)
  //           }
  //         }}>{title}</div>}
  //         role="button"
  //       >
  //         {menuItems(node.childNodes)}
  //       </Menu.SubMenu>
  //     } else {
  //       return <Menu.Item key={node.key} onClick={() => navigate(node.key)} role="button">
  //         {node.node.frontmatter.title}
  //       </Menu.Item>
  //     }
  //   })
  // }

  const menuItems = React.useCallback((array) => {
    return array.map(node => {
      if (node.childNodes && node.childNodes.length) {
        const title = node.node ? node.node.frontmatter.title : node.key.split("/").pop()
        return <Menu.SubMenu key={node.key} title={<div
          onClick={() => {
            if (node.node) {
              navigate(node.key === "/allAboutAixp" ? "/" : node.key)
            }
          }}>{title}</div>}
          role="button"
        >
          {menuItems(node.childNodes)}
        </Menu.SubMenu>
      } else {
        return <Menu.Item key={node.key} onClick={() => navigate(node.key)} role="button">
          {node.node.frontmatter.title}
        </Menu.Item>
      }
    })
  }, [parentDepthMap])

  return (
    <div
      css={css`
      .ant-menu,
      .ant-menu-item,
      .ant-menu-item-selected {
        background-color: transparent !important;
      }
    `}
    >
      {/* <Input.Search style={{ padding: 20 }} /> */}
      <Menu mode="inline"
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={[defaultSelectedKeys]}
        selectedKeys={selectedKey}
        onSelect={(keys) => {
          setSelectedKeys(keys.selectedKeys)
        }}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {menuItems(parentDepthMap[1])}
      </Menu>
      {/* <Menu mode="inline"
        selectedKeys={selectedKey}
        onSelect={(keys) => {
          setSelectedKeys(keys.selectedKeys)
        }}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {parentNodes.map(node => {
          if (!node.childrenNodes.length) {
            return <Menu.Item key={node.fields.slug ? node.fields.slug : "/"}>
              <Link to={node.fields.slug ? node.fields.slug : "/"}>
                {node.frontmatter.title}
              </Link>
            </Menu.Item>
          }
          return <Menu.SubMenu key={node.fields.slug ? node.fields.slug : "/"} title={<div
            onClick={() => {
              setSelectedKeys([])
              navigate(node.fields.slug ? node.fields.slug : "/")
            }}>{node.frontmatter.title}</div>}
            role="button"
          >
            {node.childrenNodes.map(childNode =>
              <Menu.Item key={childNode.fields.slug} onClick={() => navigate(childNode.fields.slug)} role="button">
                {childNode.frontmatter.title}
              </Menu.Item>
            )}
          </Menu.SubMenu>
        })}
      </Menu> */}
    </div>
  )
}

export default Sidebar
